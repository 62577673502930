<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li
            v-if="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered)"
            class="nav-item"
            style="width:85% !important"
          >
            <b-link
              class="navbar-brand"
              to="/"
            >
              <div
                class="d-flex justify-content-center"
                style="width:100% !important"
              >
                <img
                  :src="LogoImage"
                  style="width:100%;max-width:100px"
                >
              <!-- <h2 class="brand-text"> -->
              <!-- {{ 'Mortgage Envy' }} -->

              <!-- </h2> -->
              </div>

            </b-link>
          </li>

          <li class="nav-item" v-else>
            <b-link
              class="navbar-brand"
              to="/"
            >
              <img
                :src="LogoImage2"
                style="width:40%; max-width:100%; margin-left: -22px;"
              >
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <h4
        style="margin-left:38px; margin-top:80px"
        :style="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) ? '': 'margin-left:8px !important; font-size: 14px !important'"
      >
        {{ roles }}
      </h4>
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import items from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import LogoImage from '@/assets/logo.png'
import LogoImage2 from '@/assets/log2.png'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      recomputeKey: false,
      LogoImage,
      LogoImage2,
    }
  },
  computed: {
    hasApplications() {
      return this.$store.getters['auth/loanApplicationsCount'] > 0
    },
    roles() {
      return this.$store.getters['auth/role']
    },
    navMenuItems() {
      // eslint-disable-next-line no-unused-expressions
      this.recomputeKey
      let menus = items.client
      const userType = this.$store.getters['auth/userType']
      if (userType === 'admin') {
        menus = items.admin
      }
      if (userType === 'borrower') {
        if (this.hasApplications) {
          menus = items.client.filter(menu => menu.title !== 'Questionnaire')
        } else {
          menus = items.client
        }
      }
      if (userType === 'licensee') {
        menus = items.agent
      }
      if (userType === 'staff') {
        menus = items.staff
      }
      return menus
    },
  },
  watch: {
    hasApplications(apps) {
      this.recomputeKey = !this.recomputeKey
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
