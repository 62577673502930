<template>
  <!-- Footer -->
</template>

<script>
import { BLink, BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BFormCheckbox,
  },
  computed: {
    footerContent() {
      return this.$store.getters['auth/footerContent']
    },
  },
  mounted() {
    this.getFooterContent()
  },
  methods: {
    getFooterContent() {
      this.$http.get('api/footer-content')
        .then(res => {
          this.$store.commit('auth/setFooterContent', res.data)
          this.loaded = true
        })
    },
  },
}
</script>

<style scoped>
  /*-- End Subscribe --*/
  /*-- Footer --*/
  .one-footer-area {
    padding-top: 30px;
    background-color: #fff;
  }

  .one-footer-area .footer-item {
    margin-bottom: 30px;
  }

  .one-footer-area .footer-item .footer-logo a {
    display: block;
    margin-bottom: 35px;
  }

  .one-footer-area .footer-item .footer-logo p {
    font-size: 16px;
    color: #bdcbd5;
    margin-bottom: 14px;
  }

  .one-footer-area .footer-item .footer-logo ul {
    margin: 0;
    padding: 0;
    padding-top: 20px;
  }

  .one-footer-area .footer-item .footer-logo ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 5px;
  }

  .one-footer-area .footer-item .footer-logo ul li:last-child {
    margin-right: 0;
  }

  .one-footer-area .footer-item .footer-logo ul li a {
    display: block;
    border: 1px solid #8f8f8f;
    text-align: center;
    border-radius: 50%;
    color: #8f8f8f;
    font-size: 18px;
    margin-bottom: 0;
  }

  .one-footer-area .footer-item .footer-logo ul li a:hover {
    color: #262626;
    background-color: #8f8f8f;
  }

  .one-footer-area .footer-item .footer-logo ul li a i {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .one-footer-area .footer-item .footer-quick h3 {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 25px;
    color: #000000;
  }

  .one-footer-area .footer-item .footer-quick ul {
    margin: 0;
    padding: 0;
  }

  .one-footer-area .footer-item .footer-quick ul li {
    list-style-type: none;
    display: block;
    margin-bottom: 11px;
  }

  .one-footer-area .footer-item .footer-quick ul li:last-child {
    margin-bottom: 0;
  }

  .one-footer-area .footer-item .footer-quick ul li a {
    display: block;
    font-size: 16px;
    color: #181818;
  }

  .one-footer-area .footer-item .footer-quick ul li a:hover {
    letter-spacing: 1px;
  }

  .one-footer-area .footer-item .footer-address h3 {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 25px;
    color: #111111;
  }

  .one-footer-area .footer-item .footer-address ul {
    margin: 0;
    padding: 0;
  }

  .one-footer-area .footer-item .footer-address ul li {
    list-style-type: none;
    display: block;
    font-size: 16px;
    color: #101010;
    margin-bottom: 15px;
    position: relative;
    padding-left: 0px !important;
  }

  .one-footer-area .footer-item .footer-address ul li:last-child {
    margin-bottom: 0;
  }

  .one-footer-area .footer-item .footer-address ul li i {
    position: absolute;
    top: 2px;
    left: 0;
    font-size: 20px;
    background: -webkit-gradient(linear, left top, right top, color-stop(23%, #10c317), color-stop(64%, #15d09a));
    background: linear-gradient(90deg, #10c317 23%, #15d09a 64%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .one-footer-area .footer-item .footer-address ul li a {
    color: #131313;
  }

  .one-footer-area .footer-item .footer-address ul li a:hover {
    letter-spacing: 1px;
  }

  .one-footer-area .copyright-area {
    margin-top: 70px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #212e37;
  }

  .one-footer-area .copyright-area .copyright-item ul {
    margin: 0;
    padding: 0;
  }

  .one-footer-area .copyright-area .copyright-item ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 8px;
  }

  .one-footer-area .copyright-area .copyright-item ul li:last-child {
    margin-right: 0;
  }

  .one-footer-area .copyright-area .copyright-item ul li a {
    display: block;
  }

  .one-footer-area .copyright-area .copyright-item ul li a:hover {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
  }

  .one-footer-area .copyright-area .copyright-item p {
    margin-bottom: 0;
    font-size: 16px;
    color: #767676;
    text-align: right;
  }

  .one-footer-area .copyright-area .copyright-item p a {
    display: inline-block;
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }

  .one-footer-area .copyright-area .copyright-item p a:hover {
    letter-spacing: 1px;
  }
</style>
