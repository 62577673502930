<template>

  <div
    v-if="isPublic"
    class="navbar-container d-flex content align-items-center"
  >
    <b-modal
      id="modal-sm"
      v-model="show"
      cancel-variant="secondary"
      ok-only
      ok-title="Continue"
      centered
      size="sm"
      title="Account Type"
      :ok-disabled="type===null"
      @ok="goToRegister()"
      @close="()=>{show=false}"
    >
      <b-form-select
        v-model="type"
        placeholder="Select Account Type"
        :options="options"
      />
    </b-modal>
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <b-avatar
        text="ME"
        variant="success"
        size="50"
      />
      <div class="mr-2"/>
      <b-button
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        variant="flat-warning"
        @click="scrollToEl('hwa-home')"
      >
        Home
      </b-button>
      <div class="mr-1" />
      <b-button
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        variant="flat-warning"
        @click="scrollToEl('hwa-about-us')"
      >
        About Us
      </b-button>
      <div
        v-if="faqs.length>0"
        class="mr-1"
      />
      <b-button
        v-if="faqs.length>0"
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        variant="flat-warning"
        @click="scrollToEl('hwa-faqs')"
      >
        FAQs
      </b-button>
      <div
        v-if="reviews.length>0"
        class="mr-1"
      />
      <b-button
        v-if="reviews.length>0"
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        variant="flat-warning"
        @click="scrollToEl('hwa-testimonial')"
      >
        Testimonials
      </b-button>
      <div class="mr-1" />
      <b-button
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        variant="flat-warning"
        @click="scrollToEl('hwa-contact')"
      >
        Contact Us
      </b-button>
    </div>
    <b-navbar-nav
      v-if="isLoggedIn"
      class="nav align-items-center ml-auto"
    >
      <b-button
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        variant="warning"
        @click="navigateToAccount()"
      >
        <feather-icon
          size="16"
          class="mr-50"
          icon="ExternalLinkIcon"
        />
        Account
      </b-button>
    </b-navbar-nav>
    <b-navbar-nav
      v-else
      class="nav align-items-center ml-auto"
    >
      <b-button
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        variant="flat-warning"
        @click="navigateTo('login')"
      >
        Sign In
      </b-button>
      <div class="mr-1" />
      <b-button
        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
        :disabled="!loaded"
        variant="warning"
        @click="inProgress?continueQuestions():getStarted()"
      >
        <feather-icon
          size="16"
          class="mr-50"
          icon="ExternalLinkIcon"
        />
        {{ inProgress?'Continue':'Get Started' }}
      </b-button>
    </b-navbar-nav>
  </div>
  <div
    v-else
    class="navbar-container d-flex content align-items-center"
  >
    <v-idle
      :loop="false"
      :reminders="[10]"
      :wait="5"
      :duration="600"
      style="visibility: hidden;"
      @idle="onidle"
      @remind="onremind"
    />
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ appBarData.name || 'Administrator' }}
            </p>
            <span class="user-status">{{ appBarData.role||'Admin'
            }}</span>
          </div>
          <b-avatar
            :src="appBarData.avatar"
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="viewProfile()"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

  </div>

</template>

<script>
import {
  BLink, BNavbarNav, BModal, BFormSelect, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BButton,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import navigateToHome from '@/mixins/navigateToHome'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BModal,
    BFormSelect,
    BButton,
  },
  mixins: [navigateToHome],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      loaded: false,
      show: false,
      type: null,
      options: [{ text: 'Account Type', value: null }, { text: 'Client', value: 0 }, { text: 'Real Estate Agent', value: 1 }],
    }
  },
  computed: {
    faqs() {
      return this.$store.getters['auth/faqs']
    },
    reviews() {
      return this.$store.getters['auth/reviews']
    },
    isPublic() {
      return this.$route.meta.public
    },
    inProgress() {
      return this.stepData?.step > 0
    },
    stepData() {
      return this.$store.getters['auth/stepData']
    },
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
    navMenuHidden() {
      return this.$store.state.appConfig.layout.menu.hidden
    },
    appBarData() {
      return this.$store.getters['auth/appBarData']
    },
  },
  mounted() {
    this.getProgress()
  },
  methods: {
    onidle() {
      this.logout()
    },
    onremind(time) {
      // alert seconds remaining to 00:00
      this.idleWarning(time)
    },
    idleWarning(time) {
      this.$swal({
        title: 'Warning!',
        text: `You have left this app idle for 10mins, left with ${time} seconds to logout the app.`,
        icon: 'warning',
        timer: 6000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    continueQuestions() {
      const { step } = this.stepData
      const { type } = this.stepData
      let route = 'step-'
      switch (step) {
        case 1:
          if (type === 'refinance') {
            route = 'r-step-'
          }
          this.$store.state.auth.getStartedCompleted = true
          break
        case 2:
          if (type === 'refinance') {
            route = 'r-step-'
          }
          this.$store.state.auth.stepOneCompleted = true
          break
        case 3:
          if (type === 'refinance') {
            route = 'r-step-'
          }
          this.$store.state.auth.stepTwoCompleted = true
          break
        case 4:
          if (type === 'refinance') {
            route = 'r-step-'
          }
          this.$store.state.auth.stepThreeCompleted = true
          break
      }
      this.$router.push({ name: `${route}${step}` })
    },
    getProgress() {
      const id = localStorage.getItem('anonymous')
      this.$http.get(`api/questions/progress/${id}`)
        .then(res => {
          this.$store.commit('auth/setStep', res.data)
          this.loaded = true
        })
    },
    getStarted() {
      this.$router.push({ name: 'get-started' })
    },
    navigateToAccount() {
      this.navigateToHome(this.$store.getters['auth/role'])
    },
    showConfirm() {
      this.show = true
    },
    viewProfile() {
      this.$router.push({ name: 'profile' })
    },
    goToRegister() {
      let route
      switch (this.type) {
        case 0:
          route = 'register'
          break
        case 1:
          route = 'agent-register'
          break
        case 3:
          route = 'team-register'
          break
        default:
          route = 'register'
      }
      this.$router.push({ path: `/auth/${route}` })
    },
    scrollToEl(id) {
      if (this.$route.name !== 'welcome') {
        this.$router.push({ name: 'welcome', params: { id } })
      } else {
        document.getElementById(id)
          .scrollIntoView()
      }
    },
    navigateTo(routeName) {
      this.$router.push({ name: routeName })
    },
    logout() {
      this.$http
        .post('/api/auth/logout').then(res => {
          this.$store.commit('auth/clearAuthState')
          const isStaff = localStorage.getItem('isStaff')
          if (isStaff === 'true') {
            this.$router.push({ name: 'staff-login' })
          } else {
            this.$router.push({ name: 'login' })
          }
        })
    },
  },
}
</script>
