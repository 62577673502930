export default {
  admin: [
    {
      title: 'Data Center',
      route: 'admin-dashboard',
      icon: 'DatabaseIcon',
    },
    // {
    //   title: 'Assign Roles',
    //   route: 'assign-roles',
    //   icon: 'CircleIcon',
    // },
    {
      title: 'Manage',
      icon: 'CommandIcon',
      children: [
        {
          title: 'Managers',
          route: 'manage-managers',
          icon: 'CircleIcon',
          resource: 'hwa',
          action: 'view-members',
        },
        {
          title: 'User Access',
          route: 'manage-user-access',
          icon: 'CircleIcon',
          resource: 'hwa',
          action: 'view-members',
        },

      ],
    },
    // {
    //   title: 'Data Center',
    //   route: 'data-center',
    //   icon: 'DatabaseIcon',
    // },
    {
      title: 'Contents',
      icon: 'SettingsIcon',
      children: [
        {
          title: 'Blog Posts',
          route: 'blog-posts',
          icon: 'CircleIcon',
        },
        {
          title: 'FAQs',
          route: 'faqs',
          icon: 'CircleIcon',
        },
        {
          title: 'Mortgage Types',
          route: 'services',
          icon: 'CircleIcon',
        },
        {
          title: 'Carousel',
          route: 'carousel-content',
          icon: 'CircleIcon',
        },
        {
          title: 'Contact',
          route: 'contact-content',
          icon: 'CircleIcon',
        },
        {
          title: 'About',
          route: 'about-content',
          icon: 'CircleIcon',
        },
        {
          title: 'Footer',
          route: 'footer-content',
          icon: 'CircleIcon',
        },
        {
          title: 'Terms of Use',
          route: 'terms-content',
          icon: 'CircleIcon',
        },
        {
          title: 'Disclosures & Licensing',
          route: 'disclosure-content',
          icon: 'CircleIcon',
        },
        {
          title: 'Affiliated Business',
          route: 'affiliated-content',
          icon: 'CircleIcon',
        },
        {
          title: 'Browser Disclaimer',
          route: 'disclaimer-content',
          icon: 'CircleIcon',
        },
        {
          title: 'Privacy Policy',
          route: 'privacy-content',
          icon: 'CircleIcon',
          resource: 'hwa',
          action: 'update-privacy-policy',
        },
        {
          title: 'Glossary Content',
          route: 'glossary-content',
          icon: 'CircleIcon',
          resource: 'hwa',
          action: 'update-glossary-content',
        },
        {
          title: 'Partner With Us',
          route: 'partner-content',
          icon: 'CircleIcon',
        },
        {
          title: 'Careers',
          route: 'careers-content',
          icon: 'CircleIcon',
        },
      ],
    },
  ],
  client: [
    {
      title: 'Mortgage Files',
      route: 'customer-applications',
      icon: 'BarChart2Icon',
    },
    // {
    //   title: 'Questionnaire',
    //   route: 'questionnaires',
    //   icon: 'HelpCircleIcon',
    // },
    {
      title: 'Real Estate Licensees',
      route: 'licensees',
      icon: 'UsersIcon',
    },
    // {
    //   title: 'Mortagages',
    //   route: 'customer-applications',
    //   icon: 'GridIcon',
    // },
    {
      title: 'Reviews',
      route: 'app-reviews',
      icon: 'CastIcon',
    },
    // {
    //   title: 'Message',
    //   route: 'chats',
    //   icon: 'MessageCircleIcon',
    // },
  ],
  agent: [
    {
      title: 'Dashboard',
      route: 'clients',
      icon: 'BarChart2Icon',
    },
    // {
    //   title: 'Clients',
    //   route: 'clients',
    //   icon: 'UsersIcon',
    // },
    {
      title: 'Reviews',
      route: 'app-reviews',
      icon: 'CastIcon',
    },
    // {
    //   title: 'Mortagages',
    //   route: 'chats',
    //   icon: 'MessageCircleIcon',
    // },

  ],
  staff: [
    {
      title: 'Mortgage Files',
      route: 'manage-applications',
      icon: 'BarChart2Icon',
      resource: 'hwa',
      action: 'view-dashboard',
    },

    // {
    //   title: 'Message Center',
    //   route: 'message-center',
    //   icon: 'MessageCircleIcon',
    // },
    // {
    //   title: 'Associate Licensees',
    //   route: 'associate-licensees',
    //   icon: 'UsersIcon',
    // },
    // {
    //   title: 'Mortgages',
    //   route: 'manage-applications',
    //   icon: 'MessageCircleIcon',
    // },
    {
      title: 'Borrowers',
      route: 'manage-clients',
      icon: 'UsersIcon',
      resource: 'hwa',
      action: 'view-borrowers',
    },
    {
      title: 'Real Estate Licensees',
      route: 'manage-agents',
      icon: 'UsersIcon',
      resource: 'hwa',
      action: 'view-real-estate-licensees',
    },
    {
      title: 'Company Licenses',
      route: 'manage-companies',
      icon: 'LayersIcon',
      resource: 'hwa',
      action: 'view-blog-posts',
    },
    {
      title: 'Blog Posts',
      route: 'blog-posts',
      icon: 'GridIcon',
      resource: 'hwa',
      action: 'view-blog-posts',
    },
    {
      title: 'Reviews',
      route: 'approve-reviews',
      icon: 'TwitchIcon',
      resource: 'hwa',
      action: 'view-reviews',
    },

    {
      title: 'Settings',
      icon: 'SettingsIcon',
      children: [
        {
          title: 'Staff',
          route: 'manage-staff',
          icon: 'CircleIcon',
          resource: 'hwa',
          action: 'view-staff-information',
        },
        // {
        //   title: 'Applications',
        //   route: 'manage-applications',
        //   icon: 'CircleIcon',
        //   resource: 'hwa',
        //   action: 'manage-applications',
        // },
        // {
        //   title: 'Borrowers',
        //   route: 'manage-clients',
        //   icon: 'CircleIcon',
        //   resource: 'hwa',
        //   action: 'manage-clients',
        // },
        // {
        //   title: 'Real Estate Licensees',
        //   route: 'manage-agents',
        //   icon: 'CircleIcon',
        //   resource: 'hwa',
        //   action: 'manage-agents',
        // },
        {
          title: 'Roles',
          route: 'manage-roles',
          icon: 'CircleIcon',
          resource: 'hwa',
          action: 'view-roles',
        },

      ],
    },
  ],
}
